/**
 * Animations
 *
 */

import ScrollMagic from 'scrollmagic';
import 'gsap';
import globals from '../settings/globals.js'
import 'scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap.js'
import 'scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators.js'
import $ from 'jquery';

if ($('[data-animate-parallax="parent"]').length > 0) {
  let heroScrollController = new ScrollMagic.Controller({globalSceneOptions: {
    duration: '100%',
    offset: -62,
    triggerHook: 0
  }});

  let heroParallaxAnimation = new ScrollMagic.Scene({triggerElement: '[data-animate-parallax="parent"]'})
    .setTween('[data-animate-parallax="element"]', {y: 200, ease: window.Linear.easeNone})
    .addTo(heroScrollController);

  if (globals.debug) {
    heroParallaxAnimation.addIndicators()
  }
}

let $animateTriggers = $('[data-animate-trigger]');

if ($animateTriggers.length > 0) {
  let triggerController = new ScrollMagic.Controller({globalSceneOptions: {
    duration: 0,
    offset: 0,
    triggerHook: 1
  }});

  $animateTriggers.each(function () {
    let triggerScene = new ScrollMagic.Scene({triggerElement: this})
      .setClassToggle(this, 'start-animate')
      .addTo(triggerController);

    triggerScene.on('enter', function () {
      triggerScene.destroy();
    });

    if (globals.debug) {
      triggerScene.addIndicators();
    }
  });
}

let $sectionTriggers = $('[data-section]');
let $indicators = $('[data-indicators]');

if ($sectionTriggers.length > 0) {
  let sectionController = new ScrollMagic.Controller({globalSceneOptions: {
    offset: 0,
    triggerHook: 0.5
  }});

  $sectionTriggers.each(function () {
    let sectionScene = new ScrollMagic.Scene({triggerElement: this})
      .duration(this.offsetHeight)
      .setClassToggle(this, 'section--active')
      .addTo(sectionController);

    sectionScene.on('enter', function () {
      let $this = $(this);
      let index = $(this).attr('data-section');

      if ($this.hasClass('section--theme-dark')) {
        $indicators.addClass('indicators__list--dark');
      } else {
        $indicators.removeClass('indicators__list--dark');
      }

      $indicators.find('[data-indicator]').removeClass('active');
      $($indicators.find('[data-indicator]').get(index - 1)).addClass('active');

    }.bind(this));

    if (globals.debug) {
      sectionScene.addIndicators();
    }
  });
}
