/**
 *  Scroll next
 *
 */

import globals from '../settings/globals';
import $ from 'jquery';
import utilities from '../utilities/utilities.js';

var scrollNext = {
  $action: $('[data-scroll-next]'),

  init: function () {
    this.$action.on('click', function (e) {
      e.preventDefault();

      var $current = $(e.currentTarget),
        $next = $current.closest('section').next();

      utilities.scrollAnimation($next.offset().top);
    })
  }
};

scrollNext.init();

export default scrollNext;
