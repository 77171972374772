/**
 *  Indicators navigation
 *
 */

import $ from 'jquery';
import  utilities from '../utilities/utilities.js';

function toArray(obj) {
  return Array.prototype.slice.call(obj);
}

const ITEM_SELECTOR = '.indicators__item';

class IndicatorsNav {
  constructor() {
    const items = document.querySelectorAll(ITEM_SELECTOR);
    toArray(items).forEach(item => {
      item.addEventListener('click', () => {
        const target = item.getAttribute('data-target');
        utilities.scrollAnimation($(target).offset().top);
      });
    });
  }
}

export default IndicatorsNav;
