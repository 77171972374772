/*
  Project: Help Capital
  Author: Xfive
 */

import './modules/animations.js';
import './modules/scroll-next.js';
import IndicatorsNav from './modules/indicators-nav.js';

new IndicatorsNav();
