/**
 * Utilities
 *
 */

import scrollAnimation from './scrollAnimation.js';
import getClosest from './getClosest.js';
import getTransitionEvent from './getTransitionEvent.js';
import buildSelectorAttribute from './buildSelectorAttribute.js';

var utilities = {
  scrollAnimation: scrollAnimation,
  getClosest: getClosest,
  getTransitionEvent: getTransitionEvent,
  buildSelectorAttribute: buildSelectorAttribute
};

export default utilities;
