/**
 * Globals
 *
 */

var globals= {
  breakpoints: {
    xs: 480,
    sm: 768,
    md: 1025,
    lg: 1250
  }
};

export default globals;
